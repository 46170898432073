
<template>
  <div v-bind:style="{ padding: '10px', height: '100%',display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }">
    <div>
    <div :style="{display: 'flex', justifyContent: 'space-between', alignItems: 'end'}">
      <span :style="{ padding: '10px 5px', fontSize: '1rem', fontWeight: '600', color: '#000' }">{{ title === 'latest' ? '최근 게시글' : title }}</span>
      <span v-show="this.$route.name === 'dashboard.latest'" :style="{ padding: '10px', fontSize: '0.8rem', fontWeight: '600', color: '#000'}" @click="more">more ></span>
    </div>
    <b-table
        id="list-table"
        :small="true"
        :items="items"
        :fields="fieldsType === 'list' ? ListFields : SpanFields"
        :busy="isBusy"
        :hover="true"
        :fixed="true"
        :per-page="perPage"
        :no-border-collapse="true"
        @row-clicked="more"
        >

      <!-- 테이블 로딩 시작-->
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle" :style="{color: '#032D98', paddingRight: '10px'}"></b-spinner>
        </div>
      </template>
      <!-- 테이블 로딩 끝-->

      <!-- No. -->
      <template #cell(index)="data">
        <span :style="{ fontSize: '0.7rem', color: '#000', fontWeight: 500 }">
          {{ (total) - (perPage * (currentPage - 1) + data.index + 1) }}
        </span>
      </template>

      <!-- 거래 종류 -->
      <template #cell(type)="data">
        <span v-if="data.item.out_in === 0 && data.value === 1" :style="{ fontSize: '0.8rem', color: '#008800', fontWeight: 500 }">
          꽃달기
        </span>
        <span v-if="data.item.out_in === 0 && data.value === 2" :style="{ fontSize: '0.8rem', color: '#ff0000', fontWeight: 500 }">
          응원하기
        </span>
        <span v-if="data.item.out_in === 1 && data.value === 1" :style="{ fontSize: '0.8rem', color: '#ff00ff', fontWeight: 500 }">
          게시글 작성
        </span>
        <span v-if="data.item.out_in === 1 && data.value === 2" :style="{ fontSize: '0.8rem', color: '#0000ff', fontWeight: 500 }">
          응원받음
        </span>
        <span v-if="data.item.out_in === 1 && data.value === 4" :style="{ fontSize: '0.8rem', color: '#ff00ff', fontWeight: 500 }">
          일반 하위레퍼럴 게시글 작성
        </span>
        <span v-if="data.item.out_in === 1 && data.value === 5" :style="{ fontSize: '0.8rem', color: '#ff00ff', fontWeight: 500 }">
          유튜버 하위레퍼럴 게시글 작성
        </span>
        <span v-if="data.item.out_in === 1 && data.value === 6" :style="{ fontSize: '0.8rem', color: '#4C00FF', fontWeight: 500 }">
          추천인(하위 레퍼럴 등록)
        </span>
      </template>

      <!-- 타겟 주소 -->
      <template #cell(coin)="data">
        <span :style="{ fontSize: '0.7rem', color: '#000', fontWeight: 500 }">
          {{ data.value.toLocaleString() }} TIPS
        </span>
      </template>

      <!-- 사용일 변경 -->
      <template #cell(completed_time)="data">
        <span :style="{ fontSize: '0.7rem', color: '#000', fontWeight: 500 }">
          {{ data.value }}
        </span>
      </template>

      <!-- 보러가기 변경
      <template #cell(#)="data">
        <b-button
          @click="more(data.item)"
          variant="primary" :style="{ fontSize: '0.7rem' }">
          더보기
        </b-button>
      </template> -->

    </b-table>
    </div>
    <div>
    <b-pagination
    class="customPagination"
      v-model="currentPage"
      align='center'
      @page-click="pageClick"
      :total-rows="total"
      :per-page="perPage"
      aria-controls="list-table"
      :first-number="true"
      :last-number="true" />
    </div>
  </div>
</template>
<script>

import { mapGetters } from 'vuex'

export default {
  name: 'UseageForm',
  props: {
    isBusy: { type: Boolean },
    title: { type: String },
    items: { type: Array },
    total: { type: Number }, // 카테고리별 총 개수
    fieldsType: { type: String },
    color: {},
    getMore: {},
    path: {}
  },
  mounted () {
  },
  methods: {
    more (item) {
      if (item.type === 6) {
        return
      }
      let itemCategory = ''
      let stop = false

      for (let i = 0; i < this.categoryState.length; i++) {
        const list = this.categoryState[i].subcategory
        for (let j = 0; j < list.length; j++) {
          if (item.category === list[j].category_name) {
            itemCategory = this.categoryState[i].category_name
            stop = true
            break
          }
        }
        if (stop) {
          break
        }
      }

      if (itemCategory === '' || item.category === undefined) {
        alert('현재 존재하지 않은 게시글입니다.')
        return
      }

      this.$router.push({
        name: 'dashboard.detail',
        params: {
          item: itemCategory,
          category: item.category,
          pk: item.community_id
        }
      })
    },
    pageClick (button, page) {
      this.$emit('setBusy')
      this.getMore(page - 1, this.search)
        .then(res => {
          if (res.status !== 200) {
            return alert('서버 오류')
          }
          if (res.data.resultCode === 'false') {
            alert('서버 오류')
            this.$emit('setBusy')
            return
          }
          this.$emit('setBusy')
          this.$emit('setItem', res.data.list, res.data.total)
        })
    }
  },
  computed: {
    ...mapGetters({
      categoryState: 'Category/categoryState'
    })
  },
  data () {
    return {
      search: '',
      category: this.$route.params.category,
      itemList: [],
      perPage: 10, // 1페이지당 몇개씩
      currentPage: 1, // 현재 페이지
      SpanFields: [
        {
          key: 'title',
          label: '',
          thStyle: { display: 'none' },
          tdClass: 'text-start',
          thClass: 'text-start bg-white text-dark'
        }],
      ListFields: [
        {
          key: 'index',
          label: 'NO.',
          thStyle: { background: this.$props.color, width: '5%' },
          tdClass: 'text-center align-middle text-truncate',
          thClass: 'text-center align-middle text-truncate'
        },
        {
          key: 'type',
          label: '내역',
          thStyle: { background: this.$props.color },
          tdClass: 'text-center align-middle text-truncate',
          thClass: 'text-center align-middle text-truncate'
        },
        {
          key: 'coin',
          label: '개수',
          thStyle: { background: this.$props.color },
          tdClass: 'text-center align-middle text-truncate',
          thClass: 'text-center align-middle text-truncate'
        },
        {
          key: 'date',
          label: '사용 날짜',
          thStyle: { background: this.$props.color },
          tdClass: 'text-center align-middle text-truncate',
          thClass: 'text-center align-middle text-truncate'
        }
        // {
        //   key: '#',
        //   label: '보러가기',
        //   thStyle: { background: this.$props.color },
        //   tdClass: 'text-center align-middle text-truncate',
        //   thClass: 'text-center align-middle text-truncate'
        // }
      ]
    }
  }
}
</script>
<style>
.page-item .page-link {
    background-color: #fff !important;
    color: #000 !important;
    border-color: #797979 !important;
}
</style>
